<template>
  <div class="benefits-menu">
    <div class="header success darken1">{{ $t("benefits.menuTitle") }}</div>
    <div class="content pa-3">
      <template
        v-if="
          (benefits && benefits.length > 0) ||
            (giftCertificatesReceived && giftCertificatesReceived.length > 0)
        "
      >
        <GiftMenuCard
          v-for="(benefit, index) in benefits"
          :key="index"
          :benefit="benefit"
          :index="index"
        />
        <div
          v-for="gift in giftCertificatesReceived"
          :key="gift.userGiftCertificateId"
          class="gift-certificate-card d-flex align-center py-4"
        >
          <v-avatar color="secondary" class="mr-4" size="48" rounded>
            <span class="white--text text-h5">{{ gift.amount }}&euro;</span>
          </v-avatar>
          <div class="gift-certificate-content flex-grow-1">
            <span class="d-block text-body-2 font-weight-semibold mb-1">
              {{ gift.description }}
            </span>
            <div
              v-if="gift.endDate"
              class="expiration-date lighten-3"
              v-html="
                `<span class='font-weight-semibold text-uppercase'>${$t(
                  'checkout.expires'
                )}</span>: ${$t('benefits.endDate')} 
                ${$dayjs(gift.endDate).format('DD/MM')}`
              "
            ></div>
          </div>
          <v-btn
            color="primary"
            depressed
            small
            @click.stop="addUserGift(gift.userGiftCertificateId)"
          >
            {{ $t("benefits.useBtn") }}
          </v-btn>
        </div>
      </template>
      <p v-else class="no-treshold">
        {{ $t("benefits.noBenefits") }}
      </p>
      <div class="d-flex justify-end align-center mt-6">
        <v-btn large depressed color="primary" :to="{ name: 'ProfileGift' }">
          {{ $t("benefits.viewAll") }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.benefits-menu {
  $base-color: #ff8800;
  width: 80vw;
  max-height: 80vh;
  max-width: 600px;
  font-size: 12px;

  .header {
    color: $white;
    padding: 5px 12px;
    font-size: 14px;
  }
  .content {
    min-height: 90px;
    background-color: $white;
  }
  .progress-wraper {
    background-color: var(--v-grey-lighten1);
    border-radius: 75px;
    overflow: hidden;
    height: 10px;
    margin: 10px 0;
    .progress-bar {
      background-color: $base-color;
      height: 10px;
    }
  }

  .gift-certificate-card,
  .gift-menu-card {
    border-bottom: 1px solid var(--v-grey-base);
  }
  .benefit-descr {
    p {
      margin-bottom: 0px;
    }
  }
}
</style>
<script>
import GiftMenuCard from "./GiftMenuCard.vue";

import { mapActions } from "vuex";
export default {
  name: "BenefitsMenu",
  components: { GiftMenuCard },
  props: {
    benefitMenuIsOpen: { type: Boolean, default: false },
    benefits: { type: Array, default: () => [] },
    giftCertificatesReceived: { type: Array, default: () => [] }
  },

  methods: {
    ...mapActions({
      addGiftCode: "cart/addGiftCode"
    }),
    async addUserGift(userGiftCertificateId) {
      await this.addGiftCode(userGiftCertificateId);
    },
    goToGifts() {
      if (this.isCordova) {
        global.EventBus.$emit("closeBenefitsDialog");
      }
      this.$emit("close");
      this.$router.push("/profile/gifts");
    }
  }
};
</script>
